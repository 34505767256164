/* * * * * * * * * * * * * * *\
|*                           *|
|*  BADGE                    *|
|*                           *|
\* * * * * * * * * * * * * * */
.bg-primary .badge-primary {
    background: $white;
    color: $primary-color;
}

.bg-danger .badge-primary {
    background: $white;
    color: $danger;
}