/* * * * * * * * * * * * * * *\
|*                           *|
|*  BUTTON GROUP             *|
|*                           *|
\* * * * * * * * * * * * * * */
.btn-group {

  > .btn:not(:last-child),
  > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

}