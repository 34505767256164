/* * * * * * * * * * * * * * *\
|*                           *|
|*  CALENDAR                 *|
|*                           *|
\* * * * * * * * * * * * * * */
.table-calendar {
    height: 1px;
    border-width: 0;
    table-layout: fixed;

    td, th {
        height: 1px;
        min-height: 2.05rem;
        padding: .05rem .3rem;
        text-align: center;

        > a {
            min-height: 2.05rem;
            padding: .05rem .3rem;
            box-sizing: content-box;
        }

        .badge {
            font-size: 1em;

            time {
                font-size: .75em;
            }

            + .badge {
                margin-top: .15rem;
            }

        }

        &.disabled {
            cursor: not-allowed;
            background-color: $light;
        }

        &.roster-info-cell {
            background-color: $notepad-color;
            border-color: darken($notepad-color, 25%);
        }

    }

    td {
        min-height: 0;
        padding: 0;
    }

    thead {

        tr {

            &:before {
                content: '';
                display: table-cell;
            }

        }

        th {
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-size: .75rem;
            font-weight: 500;
            line-height: 1.5;

            &.d-lg-table-cell {

                @media print {
                    display: table-cell !important;
                }

            }

        }

    }

    tbody {

        th, td {
            @include transition(all .5s ease-in-out);
        }

        th {
            position: relative;
            text-align: left;

            > a {
                color: inherit;
                padding: 0;
                min-height: 0;
                cursor: copy;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

            }

            @media not print {

                &.overage {
                    background-color: $gray-150;

                    ~ td {
                        cursor: not-allowed;
                        background-color: $gray-150;

                        > a {

                            > .record {

                                &:not(.record-self) {

                                    .roster:not(.js-unmute-foreign) & {
                                        opacity: .3;
                                    }

                                }

                            }

                        }

                        &.disabled {
                            background-color: $gray-150;
                        }

                        &.roster-info-cell {
                            background-color: mix($notepad-color, black, 92.5%);
                            border-color: mix($notepad-color, black, 90%);
                        }

                    }

                    .fa-lock {
                        visibility: visible;
                    }

                }

            }

            .fa-lock {
                visibility: hidden;
                color: $gray-300;
            }

            .text-muted {
                @include transition(all .5s ease-in-out);
            }

        }

        td {
            position: relative;

            > a {
                pointer-events: none;

                > .record {
                    display: flex;
                    padding: 0;
                    border: none;
                    margin: .15rem 0;
                    line-height: 1.1;
                    background: none;

                    > .record-color,
                    > .ladda-label > .record-color {
                        flex: 0 1 10px;
                        width: 10px;
                        min-width: 10px;
                    }

                    > .record-body,
                    > .ladda-label > .record-body {
                        flex: 1 1 0;
                        min-width: 1px;
                        padding: .35rem 0 .45rem;

                        > small {

                            &:first-of-type {
                                line-height: 1.4;
                            }

                        }

                    }

                    > .ladda-label {
                        display: flex;
                        width: 100%;
                    }

                    &.loading {
                        opacity: 1 !important;
                        pointer-events: none !important;
                    }

                    &.btn-danger {

                        > .record-body,
                        > .ladda-label > .record-body,
                        &[data-loading] {
                            background-color: $danger-color;
                        }

                    }

                    &.btn-secondary {

                        > .record-body,
                        > .ladda-label > .record-body,
                        &[data-loading] {
                            background-color: $secondary-color;
                        }

                    }

                    &.btn-warning {

                        > .record-body,
                        > .ladda-label > .record-body,
                        &[data-loading] {
                            background-color: $warning-color;
                        }

                    }

                }

            }

            > form {

                textarea {
                    position: relative;
                    min-height: 100%;
                    background-color: transparent !important;
                    box-shadow: 0 0 1rem darken($notepad-color, 50%) !important;
                    resize: none;
                    z-index: 10;

                    &[readonly] {
                        background-color: transparent;
                        box-shadow: none !important;
                        z-index: 1;
                    }

                }

            }

            &.has-validation-results {
                padding-top: 1.2rem;
                background-image: linear-gradient(
                    45deg,
                    rgba(black, 0.1) 25%,
                    transparent 25%,
                    transparent 50%,
                    rgba(black, 0.1) 50%,
                    rgba(black, 0.1) 75%,
                    transparent 75%,
                    transparent);
                background-size: .25rem .25rem;

                &:before {
                    @include fa-icon();
                    @include fa-solid();
                    content: fa-content($fa-var-exclamation-triangle);
                    position: absolute;
                    top: .25rem;
                    right: 0;
                    left: 0;
                    color: rgba(black, 0.25);
                }

                &:not(.disabled) {
                    cursor: help;
                }

                @media print {
                    padding-top: 0;
                    background: none;

                    &:before {
                        content: none;
                    }

                }

            }

            &.disabled {

                > a {

                    > .record {
                        opacity: .3;
                    }

                }

            }

        }

        tr {

            &.holiday,
            &.holiday-individual {

                th {
                    color: $success;

                    .text-muted {
                        color: $success !important;
                    }

                    .fa-lock {
                        color: lighten($success, 40%);
                    }

                }

                td {

                    &.roster-info-cell {
                        border-color: darken(mix($success, $notepad-color, 10%), 25%);
                        background-color: mix($success, $notepad-color, 10%);
                    }

                }

                @media not print {

                    th, td {
                        border-color: lighten($success, 30%) lighten($success, 45%);
                        background-color: rgba($success, .1);

                        &.grid-row-2 + .grid-row-3,
                        &.grid-row-3 + .grid-row-4 {
                            border-left-color: lighten($success, 45%);
                        }

                    }

                }

            }

            @media not print {

                &.today {

                    th, td {
                        border-color: $primary-color lighten($primary-color, 30%) !important;
                        border-top-width: 5px;
                        border-bottom-width: 5px;
                        background-color: rgba($primary-color, 0.1);

                        &.disabled {
                            background-color: rgba($primary-color, 0.2);
                        }

                        &.grid-row-2 + .grid-row-3,
                        &.grid-row-3 + .grid-row-4 {
                            border-left-color: lighten($primary-color, 30%);
                        }

                        &:last-child {
                            border-right-color: $primary-color !important;
                            border-right-width: 5px;
                        }

                    }

                    th {
                        border-color: $primary-color !important;
                        background-color: $primary-color;
                        color: white;

                        > a {

                            .text-muted {
                                color: white !important;
                            }

                        }

                        .fa-lock {
                            color: lighten($primary-color, 15%);
                        }

                        &.overage {

                            ~ td {
                                background-color: rgba($primary-color, 0.2);
                            }

                        }

                    }

                    td {

                        &.roster-info-cell {
                            background-color: rgba(mix($primary-color, $notepad-color, 50%), .1);
                        }

                    }

                }

                &.highlight {

                    th, td {
                        @include transition(all 0s ease-in-out);
                        border-color: $mark-bg !important;
                        background-color: $mark-bg !important;
                    }

                    th {
                        color: inherit !important;

                        .text-muted {
                            @include transition(all 0s ease-in-out);
                            color: $text-muted !important;
                        }

                    }

                    &.holiday,
                    &.holiday-individual,
                    &.today {

                        th {

                            > a {

                                .text-muted {
                                    color: $text-muted !important;
                                }

                            }

                        }

                    }

                }

            }

        }

    }

    @media not print {

        &.selection-mode-cell {

            tbody {

                td {

                    &:not(.disabled) {

                        > a {
                            pointer-events: auto;

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: lighten($primary-color, 25%);
                                z-index: 10;
                                opacity: 0;
                                transition: $transition-fade;
                            }

                            &:after {
                                @include fa-icon();
                                position: absolute;
                                top: 50%;
                                right: 0;
                                left: 0;
                                margin: -.625rem auto 0;
                                color: $primary-color;
                                z-index: 11;
                                opacity: 0;
                                font-size: 1.25rem;
                                transition: $transition-fade;
                            }

                            &:hover {

                                &:before,
                                &:after {
                                    opacity: .75;
                                }

                            }

                            &.loading {
                                pointer-events: none !important;

                                &:before {
                                    opacity: .75 !important;
                                }

                                &:after {
                                    content: '' !important;
                                    display: inline-block;
                                    width: 1.5rem;
                                    height: 1.5rem;
                                    margin-top: -.75rem !important;
                                    vertical-align: text-bottom;
                                    border: .15em solid currentColor;
                                    border-right-color: transparent;
                                    border-radius: 50%;
                                    opacity: .75 !important;
                                    animation: spinner-border .75s linear infinite;
                                }

                            }

                        }

                        &.readonly {

                            > a {

                                &:before {
                                    background-color: $gray-300;
                                }

                                &:after {
                                    @include fa-solid();
                                    content: fa-content($fa-var-user-slash) !important;
                                    color: $gray-600;
                                }

                            }

                        }

                    }

                }

            }

            &.cell-mode-add {

                tbody {

                    tr {

                        &.blocked,
                        &.holiday-individual {

                            td {
                                cursor: not-allowed;

                                > a {
                                    pointer-events: none;
                                }

                            }

                        }

                    }

                    th {

                        &.overage {

                            ~ td {

                                > a {
                                    pointer-events: none;
                                }

                            }

                        }

                    }

                    td {

                        &:not(.disabled) {

                            > a {

                                &:after {
                                    @include fa-solid();
                                    content: fa-content($fa-var-calendar-plus);
                                }

                            }

                        }

                    }

                }

            }

            &.cell-mode-copy {

                tbody {

                    tr {

                        &.blocked,
                        &.holiday-individual {

                            td {
                                cursor: not-allowed;

                                > a {
                                    pointer-events: none;
                                }

                            }

                        }

                    }

                    th {

                        &.overage {

                            ~ td {

                                > a {
                                    pointer-events: none;
                                }

                            }

                        }

                    }

                    td {

                        &:not(.disabled) {

                            > a {

                                &:after {
                                    @include fa-solid();
                                    content: fa-content($fa-var-calendar-plus);
                                }

                            }

                        }

                    }

                }

            }

            &.cell-mode-edit {

                tbody {

                    td {

                        &:not(.disabled) {

                            > a{

                                &:after {
                                    @include fa-solid();
                                    content: fa-content($fa-var-edit);
                                }

                                &:empty {
                                    pointer-events: none;
                                }

                            }

                        }

                    }

                }

            }

        }

        &.selection-mode-record {

            tbody {

                td {

                    &:not(.disabled) {

                        > a {

                            > .record {
                                opacity: .3;

                                &:not(.readonly) {
                                    opacity: 1;
                                    pointer-events: auto;

                                    &.selected {

                                        > .record-body,
                                        > .ladda-label > .record-body,
                                        &[data-loading] {
                                            background-color: $primary-color !important;
                                            color: color-yiq($primary-color) !important;
                                        }

                                    }

                                }

                            }

                        }

                        &.readonly {

                            > a {
                                pointer-events: auto;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    background-color: $gray-300;
                                    z-index: 10;
                                    opacity: 0;
                                    transition: $transition-fade;
                                }

                                &:after {
                                    @include fa-icon();
                                    @include fa-solid();
                                    content: fa-content($fa-var-user-slash);
                                    position: absolute;
                                    top: 50%;
                                    right: 0;
                                    left: 0;
                                    margin: -.625rem auto 0;
                                    color: $gray-600;
                                    z-index: 11;
                                    opacity: 0;
                                    font-size: 1.25rem;
                                    transition: $transition-fade;
                                }

                                &:hover {

                                    &:before,
                                    &:after {
                                        opacity: .75;
                                    }

                                }

                                &:empty {
                                    pointer-events: none;
                                }

                            }

                        }

                    }

                }

            }

        }

    }

    @media (min-width: breakpoint-min(lg)), print {

        td, th {
            &.grid-row-2 + .grid-row-3,
            &.grid-row-3 + .grid-row-4 {
                border-left-width: $noon-divider-width;
                border-left-color: $noon-divider-color;
            }
        }

        thead {

            th {

                &:not(.d-none) + .d-none,
                &.d-none + :not(.d-none) {
                    border-left-width: $noon-divider-width;
                    border-left-color: $noon-divider-color;
                }

            }

        }

    }

    @media screen and (max-width: breakpoint-max(md)) {
        display: block;
        height: auto;
        margin-right: -1.5rem;
        margin-left: -1.5rem;

        td {

            > a {
                padding: .01rem .1rem;
            }

        }

        tr {
            display: grid;
            grid-template-rows: auto 0 auto auto;
            width: 100%;

            td, th {
                display: block;
                height: auto;
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 1;
                grid-column-end: 2;

                time {
                    white-space: nowrap;
                }

                &.grid-row-4 {
                    border-top-style: dotted;
                }

            }

            th {
                display: block;
                height: auto;
            }

            &.today {

                td, th {
                    border-top-width: 1px !important;

                    &.grid-row-2 {

                        ~ .grid-row-3 {
                            border-top-width: 1px;
                        }

                    }

                    &.grid-row-4 {
                        border-top-width: 1px;
                        border-bottom: 5px solid $primary-color;
                    }

                    &.grid-column-last {
                        border-right: 1px solid $primary-color;
                    }

                }

            }

            &.has-information {
                grid-template-rows: auto auto auto auto;
            }

        }

        thead, tbody {
            display: block;
            width: 100%;
        }

        thead {

            tr {
                grid-template-rows: 1fr;

                th {
                    padding-right: .1rem;
                    padding-left: .1rem;
                    grid-row-start: 1;
                    grid-row-end: 2;

                    > span {
                        font-size: 85%;
                    }

                }

                &:before {
                    content: none;
                }

            }

        }

        tbody {

            th {

                > a {
                    display: block;
                    padding-top: .75rem;
                    padding-bottom: .75rem;
                }

            }

            td {

                > a {

                    > .record {

                        > .record-body,
                        > .ladda-label > .record-body {
                            padding-top: .2rem;
                            padding-bottom: .3rem;

                            > small {

                                &:first-of-type {
                                    line-height: 1.1;
                                }

                            }

                            > .record-user {
                                font-size: 85%;
                            }

                        }

                    }

                    > .holiday {

                        > .holiday-user {
                            font-size: 85%;
                        }

                    }

                }

            }

            tr {

                &.today {

                    th, td {

                        &:last-child {
                            border-right: 1px solid lighten($primary-color, 30%) !important;
                        }

                    }

                }

            }

        }

        &.table-bordered {
            border-right-width: 1px;
            border-bottom-width: 1px;

            th, td {
                border-right: none;
            }

            thead {

                th {
                    border-bottom-width: 1px;
                }

            }

            tbody {

                th, td {
                    border-bottom: none;
                }

            }

        }

    }

    @media print {
        width: 100% !important;
    }

}