/* * * * * * * * * * * * * * *\
|*                           *|
|*  SIZING                   *|
|*                           *|
\* * * * * * * * * * * * * * */
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        .w#{$infix}-0 {
            width: 0 !important;
        }
    }
}