/* * * * * * * * * * * * * * *\
|*                           *|
|*  GRID                     *|
|*                           *|
\* * * * * * * * * * * * * * */

// Grid column helper classes
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @for $col from 1 through 12 {
            .grid-column#{$infix}-#{$col} {
                grid-column-start: $col !important;
                grid-column-end: $col + 1 !important;
            }
        }
    }
}

// Grid row helper classes
@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @for $row from 1 through 12 {
            .grid-row#{$infix}-#{$row} {
                grid-row-start: $row !important;
                grid-row-end: $row + 1 !important;
            }
        }
    }
}