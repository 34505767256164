/* * * * * * * * * * * * * * *\
|*                           *|
|*  FULL CALENDAR            *|
|*                           *|
\* * * * * * * * * * * * * * */
@media print {

  .fc {

    .fc-toolbar {

      .fc-center,
      .fc-right {
        display: none;
      }

    }

  }

  #holiday-calendar {

    > .card {
      border: none;

      > .card-body {
        padding: 0;
      }

    }

  }

}