/* * * * * * * * * * * * * * *\
|*                           *|
|*  ROSTER                   *|
|*                           *|
\* * * * * * * * * * * * * * */
.roster {
    position: relative;

    .edit-lock-toggle {
        position: relative;
        z-index: 200;
        margin-top: -.35rem;
        margin-bottom: -.3rem;
    }

    > .roster-controls {
        position: relative; // fallback for older browsers
        position: sticky;
        top: 0;
        z-index: 101;
        background-color: $body-bg;

        .toolbar,
        .user-filters {

            > .btn-group {
                display: inline;
                line-height: 1.9rem;
            }

        }

        > .roster-static-head {
            padding-right: 1.5rem;
            padding-left: 1.5rem;
            margin-right: -1.5rem;
            margin-bottom: -1px;
            margin-left: -1.5rem;
            background-color: $card-bg;
            transition: height ease-out 1s;

            > table {
                height: 1px;
                width: 100%;
                table-layout: fixed;

                tr {

                    th {
                        padding: .5rem .3rem;
                        border: $table-border-width solid $table-border-color;
                        border-bottom-width: 2px;
                        text-align: center;
                        font-size: .75rem;
                        font-weight: 500;
                        line-height: 1.5;

                        &.roster-info-cell {
                            background-color: $notepad-color;
                            border-color: darken($notepad-color, 25%);

                            + th {
                                border-left-width: 3px;
                                border-left-color: $gray-300;
                            }

                        }

                        &.roster-cell-am {

                            + .roster-cell-pm {
                                border-left-width: 3px;
                                border-left-color: $gray-300;
                            }

                        }

                    }

                    &:before {
                        content: '';
                        display: table-cell;
                        border-bottom: $table-border-width solid $table-border-color;
                    }

                }

            }

            &.hide {
                display: none;
            }

        }

    }

    > .card {

        > .card-body {

            @media print {
                padding: 0;
            }

        }

        @media print {
            border: none;
            margin-bottom: 0 !important;
        }

    }

    > .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(white, .8);

        > .spinner-border {
            position: sticky;
            top: calc(50% - 16px);
            left: calc(50% - 16px);
        }

    }

    > .edit-lock {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: not-allowed;
        z-index: 100;

        > i {

            + .tooltip {
                pointer-events: none;
            }

        }

        @media print {
            display: none;
        }

    }

    > .blockUI {

        &.blockMsg {
            top: 0 !important;
            left: 0 !important;
        }

    }

    @media screen and (max-width: breakpoint-max(md)) {
        margin-right: -1rem;
        margin-left: -1rem;

        > .roster-controls {

            .edit-lock {
                margin-top: -.5rem;

                .edit-lock-toggle {
                    min-width: 2.5rem;
                    padding: 0;
                    margin: 0;

                    &.text-danger {
                        color: white !important;
                        background-color: $danger !important;
                    }

                    &.text-success {
                        color: white !important;
                        background-color: $success !important;
                    }

                }

            }

            > .roster-static-head {
                padding-right: 0;
                padding-left: 0;
                margin-right: -.5rem;
                margin-left: -.5rem;

                > table {

                    tr {

                        th {
                            padding-right: .1rem;
                            padding-left: .1rem;

                            > span {
                                font-size: 85%;
                            }

                            &.roster-info-cell {

                                + th {
                                    border-left-width: 1px;
                                }

                            }

                        }

                        &:before {
                            content: none;
                        }

                    }

                }

            }

        }

    }

}