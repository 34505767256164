/* * * * * * * * * * * * * * *\
|*                           *|
|*  MODAL                    *|
|*                           *|
\* * * * * * * * * * * * * * */
.modal-content {

  > .modal-body {

    &.d-none {

      + hr {
        display: none;
      }

    }

    &:not(.d-none) {

      ~ .modal-body {
        margin-top: 3rem;
      }

    }

  }

}