/* * * * * * * * * * * * * * *\
|*                           *|
|*  TOOLTIP                  *|
|*                           *|
\* * * * * * * * * * * * * * */
.tooltip {

  @media print {
    display: none !important;
  }

}