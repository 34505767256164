/* * * * * * * * * * * * * * *\
|*                           *|
|*  LAYOUT                   *|
|*                           *|
\* * * * * * * * * * * * * * */
html {

    > body {

        > .layout-wrapper {

            > .layout-inner {

                > .layout-container {

                    > .layout-content {

                        > .container-fluid {

                            @media print {
                                padding: 0 !important;
                            }

                        }

                    }

                }

            }

        }

        @media print {
            background: none;
        }

    }

    &.layout-offcanvas {

        > body {

            > .layout-wrapper {
                // necessary in order to respect 'position: sticky' for child nodes
                overflow: visible;
            }

        }

    }

}