/* * * * * * * * * * * * * * *\
|*                           *|
|*  VARIABLES                *|
|*                           *|
\* * * * * * * * * * * * * * */

// Color system
$gray-150: #f0f2f4;
$gray-300: #dee2e6;

$text-muted: #a3a4a6;

$primary-color: #26b4ff;
$secondary-color: #8897aa;
$notepad-color: #fef49c;

$danger-color: #d9534f;
$warning-color: #ffd950;

$body-bg: #f5f5f5;

// Tables
$table-border-color: #e8e8e9;

// Roster
$noon-divider-width: 3px;
$noon-divider-color: $gray-300;