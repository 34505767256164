/* * * * * * * * * * * * * * *\
|*                           *|
|*  TYPOGRAPHY               *|
|*                           *|
\* * * * * * * * * * * * * * */
a {

  &:not(.btn) {

    @media print {
      text-decoration: none;
    }

  }

}