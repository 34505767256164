/* * * * * * * * * * * * * * *\
|*                           *|
|*  POPOVER                  *|
|*                           *|
\* * * * * * * * * * * * * * */
.popover {

  @media print {
    display: none !important;
  }

}