/*
 * Main style definitions.
 *
 * Author: Getdesigned GmbH
 * http://www.getdesigned.at/
 */





/*! Main Style Definitions | (c) 2019 Getdesigned GmbH | @license proprietary */





/* * * * * * * * * * * * * * *\
|*                           *|
|*  INCLUDES                 *|
|*                           *|
\* * * * * * * * * * * * * * */

// Variables
@import "variables";
@import "mixins";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/variables";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/mixins";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";

// Base
@import "base/typography";

// Utilities
@import "utility/display";
@import "utility/sizing";
@import "utility/grid";

// Components
@import "component/layout";
@import "component/dropdown";
@import "component/badge";
@import "component/button-group";
@import "component/tooltip";
@import "component/popover";
@import "component/navbar";
@import "component/modal";
@import "component/full-calendar";
@import "component/calendar";
@import "component/roster";